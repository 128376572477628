var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// @mui
import { Box } from '@mui/material';
//
import getRatio from './getRatio';
// ----------------------------------------------------------------------
var Image = forwardRef(function (_a, ref) {
    var ratio = _a.ratio, _b = _a.disabledEffect, disabledEffect = _b === void 0 ? false : _b, _c = _a.effect, effect = _c === void 0 ? 'blur' : _c, sx = _a.sx, other = __rest(_a, ["ratio", "disabledEffect", "effect", "sx"]);
    var content = (_jsx(Box, __assign({ component: LazyLoadImage, wrapperClassName: "wrapper", effect: disabledEffect ? undefined : effect, placeholderSrc: disabledEffect ? '/assets/transparent.png' : '/assets/placeholder.svg', sx: { width: 1, height: 1, objectFit: 'cover' } }, other)));
    if (ratio) {
        return (_jsx(Box, __assign({ ref: ref, component: "span", sx: __assign({ width: 1, lineHeight: 1, display: 'block', overflow: 'hidden', position: 'relative', pt: getRatio(ratio), '& .wrapper': {
                    top: 0,
                    left: 0,
                    width: 1,
                    height: 1,
                    position: 'absolute',
                    backgroundSize: 'cover !important',
                } }, sx) }, { children: content })));
    }
    return (_jsx(Box, __assign({ ref: ref, component: "span", sx: __assign({ lineHeight: 1, display: 'block', overflow: 'hidden', position: 'relative', '& .wrapper': {
                width: 1,
                height: 1,
                backgroundSize: 'cover !important',
            } }, sx) }, { children: content })));
});
export default Image;
